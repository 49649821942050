import React, { useCallback, useState, useEffect } from 'react';
import { string, object, array } from 'yup';
import { Grid, Button, FormHelperText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { IconPicker } from 'react-fa-icon-picker';
import { FormModal } from './FormModal';
import { SelectInput, TextInput, AutocompleteInput } from '../common/inputs';
import { useGlobalRefresh } from '../../hooks';
import { settingActions, systemPersistSelectors } from '../../state';
import { validateOnSubmit, removeNulls } from '../../lib';
import { sharedFormStyles } from './sharedStyles';
import { DoneIcon } from '../../themes';
import { FormGrid, FormWithPadding } from '../../components';

const useStyles = makeStyles((theme) => ({
  ...sharedFormStyles(theme),
  fieldTitle: {
    fontWeight: 500,
    marginBottom: 8,
  },
  colorPickerButton: {
    padding: '9.6px 15px 9px 15.4px',
    maxWidth: 48,
    minWidth: 48,
    height: 32,
    marginRight: 8,
    marginBottom: 8,
    color: '#FFFF',
  },
  colorPickerContainer: {
    marginRight: 48,
  },
  iconPicker: {
    marginBottom: 60,
    '&>div': {
      width: '100%',
    },
    '&>div>div': {
      width: '100%',
    },
  },
  errorMessage: {
    color: '#f44336',
  },
}));

export function CompanyCaseTagForm({ open, handleClose, editValues }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const refresh = useGlobalRefresh();
  const systemCaseTags = useSelector(systemPersistSelectors.systemCaseTags);
  const states = useSelector(systemPersistSelectors.states);

  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [editId, setEditId] = useState();
  const [state, setState] = useState({
    name: '',
    systemCaseTagID: '',
    stateIDs: [],
    color: '',
    icon: '',
    isEnabled: true,
  });

  useEffect(() => {
    if (editValues) {
      const { id, ...rest } = editValues;
      removeNulls(rest);
      setState((s) => ({ ...s, ...rest }));
      setEditId(id);
    }
  }, [editValues]);

  const onSave = useCallback(() => {
    return validateOnSubmit({ values: state, schema, setErrors })
      .then(async () => {
        setSubmitting(true);
        const data = await dispatch(
          settingActions.postCustomCompanyCaseTag({ ...state, id: editId }),
        );
        setSubmitting(false);
        if (data) {
          refresh();
          handleClose();
        }
      })
      .catch((e) => console.error('error ', e));
  }, [dispatch, editId, handleClose, refresh, state]);

  const onChange = useCallback((event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setState((curState) => ({ ...curState, [name]: value }));
  }, []);

  return (
    <FormModal
      handleClose={handleClose}
      open={open}
      title={editId ? 'Edit tag' : 'Add tag'}
      onSave={onSave}
      isEdit={!!editId}
      submitting={submitting}
    >
      <FormWithPadding>
        <FormGrid container spacing={3}>
          <Grid item xs={6}>
            <TextInput
              fullWidth
              value={state.name}
              name='name'
              onChange={onChange}
              label='Name'
              schema={schema}
              error={errors.name}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              fullWidth
              value={state.systemCaseTagID}
              name='systemCaseTagID'
              onChange={onChange}
              onClear={() =>
                setState((curState) => ({ ...curState, systemCaseTagID: '' }))
              }
              label='Category'
              menuItems={[...systemCaseTags].sort((v1, v2) =>
                v1.name.localeCompare(v2.name),
              )}
              schema={schema}
              error={errors.systemCaseTagID}
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteInput
              fullWidth
              multiple
              textFieldProps={{
                label: 'Applicable states (Defaults to all if none selected)',
                onChange: onChange,
              }}
              autocompleteProps={{
                options: states,
                value: state.stateIDs,
                name: 'stateIDs',
              }}
              schema={schema}
              error={errors.stateIDs}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.fieldTitle}>Select color</div>
            {errors.color && !state.color && (
              <FormHelperText className={classes.errorMessage}>
                Color is required
              </FormHelperText>
            )}
            <div
              className={classes.colorPickerContainer}
              schema={schema}
              error={errors.color}
            >
              {colors.map((c, i) => (
                <Button
                  key={i}
                  style={{ backgroundColor: c }}
                  className={classes.colorPickerButton}
                  name='color'
                  value={c}
                  onClick={onChange}
                >
                  {state.color === c && <DoneIcon />}
                </Button>
              ))}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.fieldTitle}>Select icon</div>
            {errors.icon && !state.icon && (
              <FormHelperText className={classes.errorMessage}>
                Icon is required
              </FormHelperText>
            )}
            <div className={classes.iconPicker}>
              <IconPicker
                name='icon'
                value={state.icon}
                onChange={(v) => setState((s) => ({ ...s, icon: v }))}
                schema={schema}
                error={errors.icon}
              />
            </div>
          </Grid>
        </FormGrid>
      </FormWithPadding>
    </FormModal>
  );
}

const schema = object().shape({
  name: string('This value must be a string').required('Tag name is required'),
  systemCaseTagID: string('This value must be a string').nullable(),
  stateIDs: array(string()).typeError('This field must be an array').nullable(),
  color: string('This value must be a string').required('Color is required'),
  icon: string('This value must be a string').required('Icon is required'),
});

const colors = [
  '#FB5F6F',
  '#F91429',
  '#C71022',
  '#FFAD66',
  '#FE831A',
  '#CC6A14',
  '#4DCA96',
  '#0FC076',
  '#0B8C56',
  '#4875F0',
  '#003FED',
  '#0032BA',
  '#FB8CD0',
  '#F940B3',
  '#C7348E',
  '#FFD154',
  '#FFBD07',
  '#CC9706',
  '#63ACB5',
  '#299AA6',
  '#1D6A73',
  '#924FF0',
  '#6506EE',
  '#5106BA',
];
