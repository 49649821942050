import { MouseEventHandler } from 'react';
import { Confirmation } from './Confirmation';

type Props = {
  title: string;
  text: string;
  btnText?: string;
  submitting?: boolean;
  handleDelete: MouseEventHandler<HTMLButtonElement>;
  handleClose: MouseEventHandler<HTMLButtonElement>;
};
export function DeleteConfirmation(props: Props) {
  const { handleDelete, ...restProps } = props;
  return (
    <Confirmation handleOk={handleDelete} btnColor='error' {...restProps} />
  );
}
