import React, { forwardRef } from 'react';
import { Chip, Tooltip } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  chips: {
    //margin: 12,
    marginTop: 12,
    marginRight: 10,
    background: '#E5E7F5',
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Roboto',
    fontSize: 14,
  },
  chipIcon: {
    color: 'rgba(0,0,0,0.6)',
  },
}));

export function DocumentChips({ documents, removeDocument }) {
  const classes = useStyles();

  const MyChip = forwardRef(function MyComponent(props, ref) {
    //  Spread the props to the underlying DOM element.
    return <Chip {...props} ref={ref}></Chip>;
  });

  return documents.map((d, i) => {
    return (
      <Tooltip title={d?.name} placement='bottom' key={i}>
        <MyChip
          key={i}
          className={classes.chips}
          label={d?.name || d}
          //title=
          style={{ overflow: 'hidden' }}
          onDelete={() => removeDocument(i)}
        />
      </Tooltip>
    );
  });
}
