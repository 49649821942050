import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLoader } from '../../hooks';

import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import {
  FlexBetween,
  FlexCenter,
  FlexColumn,
  TextInput,
} from '../../components';
import { FormModal } from '../../components/modals/FormModal';
import { AddCircleIcon, EditIcon, LocationOnIcon } from '../../themes';

import * as yup from 'yup';
import { Region } from './types';
import { pluralize, validateOnSubmit } from '../../lib';
import { resourceActions } from '../../state';

type Props = {
  regions: Region[];
  companyId: string;
  setRefreshData: (arg0: boolean) => void;
};
export const RegionDetails = ({
  regions = [],
  companyId,
  setRefreshData,
}: Props) => {
  const [regionToEdit, setRegionToEdit] = useState<Region | null>(null);

  const handleCloseEditRegionModal = useCallback(
    (doRefresh: boolean) => {
      setRegionToEdit(null);
      if (doRefresh) {
        setRefreshData(true);
      }
    },
    [setRefreshData],
  );

  return (
    <>
      {!regions.length ? (
        <Typography fontStyle='italic' marginLeft={2} marginBottom={3}>
          No regions
        </Typography>
      ) : (
        <FlexColumn gap={1} marginBottom={2}>
          {regions.map((r, i) => (
            <DetailedRegionInfo
              key={`${r.regionName}${i}`}
              region={r}
              setRegionToEdit={setRegionToEdit}
            />
          ))}
        </FlexColumn>
      )}
      <Button
        startIcon={<AddCircleIcon />}
        sx={{ color: 'primary.main' }}
        onClick={() => setRegionToEdit({} as Region)}
      >
        Region
      </Button>

      {regionToEdit && (
        <EditRegionModal
          region={regionToEdit}
          companyId={companyId!}
          handleClose={handleCloseEditRegionModal}
        />
      )}
    </>
  );
};

const DetailedRegionInfo = ({
  region,
  setRegionToEdit,
}: {
  region: Region;
  setRegionToEdit: (arg0: Region) => void;
}) => {
  const numStates = region.states?.length;
  return (
    <Box border={1} borderColor='action.focus' borderRadius='3px' padding={1.5}>
      <FlexBetween>
        <FlexCenter>
          <LocationOnIcon />
          <Typography variant='subtitle2' marginLeft={0.5}>
            {region.regionName}
          </Typography>
          {!!numStates && (
            <Tooltip title={region.states.join(', ')}>
              <Box display='flex'>
                <Typography variant='subtitle2' marginX={0.4}>
                  -
                </Typography>
                <Typography variant='subtitle2' color='text.disabled'>
                  {numStates} {pluralize('state', numStates)}
                </Typography>
              </Box>
            </Tooltip>
          )}
        </FlexCenter>
        <IconButton onClick={() => setRegionToEdit(region)}>
          <EditIcon />
        </IconButton>
      </FlexBetween>

      <Typography marginLeft={3.5}>
        {region.facilityNames?.join(', ')}
      </Typography>
    </Box>
  );
};

const regionSchema = yup.object().shape({
  regionName: yup.string().required('Region name is required'),
});
type EditRegionModalProps = {
  region: Region;
  companyId: string;
  handleClose: (arg0: boolean) => void;
};
const EditRegionModal = ({
  region,
  companyId,
  handleClose,
}: EditRegionModalProps) => {
  const dispatch = useDispatch();
  const setLoader = useLoader();

  const [localRegion, setLocalRegion] = useState({ ...region });
  const [errors, setErrors] = useState<Record<string, string>>({});

  const onSave = useCallback(async () => {
    validateOnSubmit({
      values: localRegion,
      schema: regionSchema,
      setErrors,
    }).then(async () => {
      setLoader(true);

      const { data } = await dispatch(
        resourceActions.postResource({
          baseUrl: `companies/${companyId}/region`,
          payload: localRegion,
        }),
      );

      if (data) {
        handleClose(true);
      }

      setLoader(false);
    });
  }, [localRegion, companyId, handleClose, dispatch, setLoader]);

  const onDeleteRegion = useCallback(async () => {
    setLoader(true);

    await dispatch(
      resourceActions.deleteResource({
        baseUrl: `companies/${companyId}/regions`,
        id: localRegion.id,
      }),
    );

    handleClose(true);
    setLoader(false);
  }, [companyId, localRegion, handleClose, dispatch, setLoader]);

  const disableDeleteButtonReason = region.facilityNames?.length
    ? 'You cannot delete a region that is currently in use. Remove this region from all facilities to delete it.'
    : '';

  const isEdit = !!region.id;
  const instructions = isEdit
    ? 'Edit the name of the region. Use the Edit Facility screen to manage facilities in the region.'
    : 'Set the name of the region. After saving, use the Edit Facility screen to manage facilities in the region.';

  return (
    <FormModal
      open
      title={`${isEdit ? 'Edit' : 'Add'} Region`}
      isEdit={isEdit}
      onSave={onSave}
      handleClose={() => handleClose(false)}
      handleDelete={onDeleteRegion}
      disableDeleteButtonReason={disableDeleteButtonReason}
      noSubmit
    >
      <Box px={3} paddingBottom={3}>
        <Typography paddingBottom={1}>{instructions}</Typography>
        <Box ml={-1}>
          <TextInput
            value={localRegion.regionName || ''}
            name='regionName'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setLocalRegion({ ...localRegion, regionName: e.target.value });
            }}
            label='Region name'
            schema={regionSchema}
            error={errors['regionName']}
          />
        </Box>
      </Box>
    </FormModal>
  );
};
