import { useCallback } from 'react';
import _isEqual from 'lodash.isequal';
import FilterOption from '../../ListViews/filters/FilterOption';
import { AssigneeDisplayButton } from '../AssigneeDisplayButton';
import {
  getAdditionalNames,
  getCaseworkerDisplay,
  IdName,
  sortById,
} from '../../../../lib';
import { useListWithInvalidSelection } from '../../../../hooks';

type Props = {
  assigneeIDs: (number | string)[];
  selectedAssignees: IdName[];
  onChange: (event: any) => void;
  assigneeOptions: IdName[];
};

export function AssigneeAutocompleteInput({
  assigneeIDs,
  selectedAssignees,
  onChange,
  assigneeOptions,
}: Props) {
  const assigneeOptionsWithInvalidSelection = useListWithInvalidSelection(
    assigneeOptions,
    selectedAssignees,
  );

  const selectedAssigneeList = assigneeOptionsWithInvalidSelection.filter((a) =>
    assigneeIDs.some((i) => i === a.id),
  );

  const onChangeAssignees = useCallback(
    (values: (number | string)[]) => {
      const oldVal = sortById(assigneeIDs, (i) => i);
      const newVal = sortById(values, (i) => i);

      if (!_isEqual(oldVal, newVal)) {
        onChange({ target: { value: values, name: 'assigneeIDs' } });
      }
    },
    [assigneeIDs, onChange],
  );

  return (
    <FilterOption
      menuItems={assigneeOptionsWithInvalidSelection}
      selectedMenuItems={selectedAssigneeList}
      label={''}
      onChange={onChangeAssignees}
      multiple
      searchPlaceholder={'Search...'}
      groupedOptionsLabel={'All users'}
      renderButton={({ onClick, ref }: any) => (
        <AssigneeDisplayButton
          displayText={getCaseworkerDisplay(
            selectedAssigneeList,
            (o) => o.name,
          )}
          tooltipText={getAdditionalNames(selectedAssigneeList, (o) => o.name)}
          actionText={'Add assignee'}
          onClick={onClick}
          otherProps={{ ref }}
        />
      )}
    />
  );
}
