import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    ...flex,
  },
  flexColumn: {
    ...flexColumn,
  },
  logoSidebarClosed: {
    position: 'absolute',
    top: '-10px',
    left: '-10px',
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  logoSidebarOpen: {
    position: 'absolute',
    top: '-10px',
    left: '200px',
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  titleContainer: {
    ...flex,
    color: '#061BEE',

    fontSize: 40,
    fontWeight: 600,
    letterSpacing: 0.6,
    marginBottom: 40,
  },
  welcomeBack: {
    color: theme.palette.textTheme.main,
    fontSize: 40,
    fontWeight: 300,
    marginRight: 7,
  },
  pageTitle: {
    color: '#000000',

    fontSize: 24,
    fontWeight: 500,
  },
  caseloadToolbar: {
    ...flex,
    justifyContent: 'space-between',
    marginBottom: 32,
  },
  boxes: {
    ...flex,
    marginBottom: 40,
  },
  boxTitle: {
    color: '#000000',

    fontSize: 24,
    fontWeight: 500,
  },
  smallBoxContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: 'auto',
    gap: 16,
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexWrap: 'wrap',
      width: 184,
    },
  },
  smallBox: {
    height: '100%',
    width: '100%',
    border: '1px solid #D5D8EC',
    borderRadius: 3,
    backgroundColor: '#FFFFFF',
    textTransform: 'capitalize',

    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '32px 16px',
  },
  boxNumber: {
    color: '#061BEE',
    fontSize: 40,
    fontWeight: 600,
    marginBottom: '24px',
  },
  boxText: {
    color: '#000000',
    fontSize: 16,
  },
  boxLabel: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  largeBoxContainer: {
    display: 'grid',
    gridTemplateColumns: '424px 400px',
    gap: 24,
    '& > div:first-child': {
      display: 'flex',
      gap: 24,
    },
    [theme.breakpoints.down('md')]: {
      flex: 1,
      display: 'block',
      '& > div:first-child': {
        marginBottom: 16,
      },
    },
  },
  tallBox: {
    height: '100%',
    width: '100%',
    borderRadius: 3,
    padding: '35px 32px',

    alignItems: 'flex-start',
    textTransform: 'capitalize',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  tallBoxDivider: {
    width: '100%',
    marginTop: 40,
    marginBottom: 24,
  },
  workloadBox: {
    // height: 355,
    width: '100%',
    border: '1px solid #D5D8EC',
    borderRadius: 3,
    backgroundColor: '#FFFFFF',
    padding: '40px 32px 48px 32px',

    position: 'relative',
    ...flexColumn,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  alertImg: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  bottomFilterBtn: {
    alignItems: 'flex-start',
    textTransform: 'capitalize',
    backgroundColor: '#FFFFFF',
  },
  bottomFilterBox: {
    height: 32,
    width: 32,
    borderRadius: 3,
    marginRight: 10,
    color: '#FFFFFF',
    fontSize: 16,
    fontWeight: 'bold',
    ...flex,
    justifyContent: 'center',
  },
  footerContainer: {
    width: '100%',
  },
  footerToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: '8px',
    },
  },
  footerText: {
    ...flex,

    fontSize: 16,
    ...flex,
  },
}));

const flex = {
  display: 'flex',
  alignItems: 'center',
};

const flexColumn = {
  display: 'flex',
  flexDirection: 'column',
};
