import { Box, Button, Grid, styled } from '@mui/material';

// styled buttons
export const AddBtn = styled(Button)(({ theme }) => ({
  cursor: 'pointer',
  letterSpacing: 0,
  fontSize: 14,
  padding: '10px 10px 10px 5px',
  borderRadius: 3,
  '&:hover': {
    backgroundColor: '#F5F6FE',
  },
}));

export const CancelBtn = styled(Button)(({ theme }) => ({
  backgroundColor: '#E5E7F5',
  '&:hover': {
    backgroundColor: '#E5E7F5',
  },
  color: theme.palette.text.primary,
}));

export const DeleteBtn = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.light,
  textTransform: 'none',
  fontWeight: 500,
}));

export const TextBtn = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.light,
  fontSize: 14,
  fontWeight: 500,
  transition: 'opacity 300ms linear',
  padding: 0,
  '&:hover': {
    opacity: '0.6',
    background: 'transparent',
  },
}));

// styled grid components
export const FormGrid = styled(Grid)(() => ({
  marginBottom: 24,
}));

export const FormTextButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.light,
  fontSize: 14,
  transition: 'all 300ms linear',
  marginLeft: 24,
  marginTop: 4,
}));

export const FormWithPadding = styled(Box)(() => ({
  padding: '0 24px',
}));
