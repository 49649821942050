import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Checkbox,
  // IconButton,
} from '@mui/material';
import clsx from 'clsx';
// import { ExpandMoreIcon } from '../../../themes';
import { useStyles } from './listLoading.styles';

const times = (nbChildren, fn) =>
  Array.from({ length: nbChildren }, (_, key) => fn(key));

export function ListLoading({
  classes,
  className,
  // expand,
  hasBulkActions,
  nbChildren,
  nbFakeLines = 5,
  size = 'medium',
}) {
  const [oneSecondHasPassed, setOneSecondHasPassed] = useState(false);
  const otherClasses = useStyles();

  useEffect(() => {
    const timeOut = setTimeout(() => setOneSecondHasPassed(true), 1000);
    return () => clearTimeout(timeOut);
  }, []);

  return oneSecondHasPassed ? (
    <Table className={clsx(classes.table, className)} size={size}>
      <TableHead>
        <TableRow className={classes.row}>
          {/* {expand && (
            <TableCell padding='none' className={classes.expandHeader} />
          )} */}
          {hasBulkActions && (
            <TableCell padding='checkbox' className={classes.expandIconCell}>
              <Checkbox
                className='select-all'
                color='primary'
                checked={false}
              />
            </TableCell>
          )}
          {times(nbChildren, (key) => (
            <TableCell variant='head' className={classes.headerCell} key={key}>
              <div className={clsx(otherClasses.root)}>&nbsp;</div>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {times(nbFakeLines, (key1) => (
          <TableRow key={key1} style={{ opacity: 1 / (key1 + 1) }}>
            {hasBulkActions && (
              <TableCell padding='checkbox' className={classes.expandIconCell}>
                <Checkbox
                  className='select-all'
                  color='primary'
                  checked={false}
                />
              </TableCell>
            )}
            {times(nbChildren, (key2) => (
              <TableCell className={classes.rowCell} key={key2}>
                <div className={clsx(otherClasses.root)}>&nbsp;</div>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : null;
}

ListLoading.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  expand: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  hasBulkActions: PropTypes.bool,
  nbChildren: PropTypes.number,
  nbFakeLines: PropTypes.number,
  size: PropTypes.oneOf(['small', 'medium']),
};
