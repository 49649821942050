import React from 'react';
import clsx from 'clsx';
import { IconButton, Tooltip } from '@mui/material';
import { ArrowDropDownIcon, PersonIcon, AddCircleIcon } from '../../../themes';
import { useStyles } from './residentDetails.styles';

/**
 *
 * @param {AssigneeDisplayProps} props
 */

export function AssigneeDisplayButton({
  displayText,
  tooltipText,
  actionText,
  onClick,
  icon,
  iconColor,
  className,
  otherProps = {},
  multiple = true,
  tooltipClasses,
}) {
  const classes = useStyles();
  const Icon = icon || PersonIcon;

  return (
    <div {...otherProps} className={classes.caseworkerDisplay}>
      <Icon
        className={clsx(classes.caseworkerIcon, className)}
        style={{ color: iconColor ?? '#FA595F' }}
      />
      {!!displayText ? (
        <>
          <Tooltip title={tooltipText} classes={tooltipClasses}>
            <span>{displayText}</span>
          </Tooltip>
          {multiple ? (
            <Tooltip title={actionText}>
              <IconButton
                className={classes.reverseNRIButton}
                onClick={onClick}
                size='large'
              >
                <AddCircleIcon style={{ height: 18 }} />
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton
              className={classes.reverseNRIButton}
              onClick={onClick}
              size='large'
            >
              <ArrowDropDownIcon style={{ height: 18 }} />
            </IconButton>
          )}
        </>
      ) : (
        <span className={classes.snapshotLink} onClick={onClick}>
          {actionText}
        </span>
      )}
    </div>
  );
}

// #region Typedefs

/**
 * @typedef {object} AssigneeDisplayProps
 * @property {string} [displayText] text to display on the button
 * @property {any} [tooltipText] text or component for the `title` of the tooltip (i.e. the 'additonal assignees' that are indicated with the +1 in the displayText) on hover of the displayText
 * @property {string} [actionText] text to display if displayText is empty; also the tooltip for the 'add' icon button if displayText is not empty.
 * @property {function} onClick onClick function for the button
 * @property {any} [icon] a material UI icon to show on the button. Default is PersonIcon
 * @property {string} [iconColor] color for the icon, default is '#FA595F'
 * @property {any}  [className] optional className for the button to override styles
 * @property {object} [otherProps] any other props for the component
 */
// #endregion
