import React, { useRef } from 'react';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Draggable from 'react-draggable';

const useStyles = makeStyles((theme) => ({
  paper: {
    alignSelf: 'flex-start',
    position: 'relative',
    top: 80,
    borderRadius: 9,
    width: 'calc(100% - 48px)',
  },
}));

export function PaperComponent(props) {
  const classes = useStyles();
  const { size } = props;
  const nodeRef = useRef(null);
  return (
    <Draggable
      handle='#draggable-dialog'
      cancel={'[class*="MuiDialogContent-root"]'}
      bounds='parent'
      nodeRef={nodeRef}
    >
      <Paper
        {...props}
        ref={nodeRef}
        className={classes.paper}
        style={{
          ...(props?.style || {}),
          maxWidth:
            size === 'xs'
              ? 444
              : size === 'sm'
              ? 600
              : size === 'md'
              ? 960
              : size === 'lg'
              ? 1280
              : 800,
        }}
      />
    </Draggable>
  );
}
