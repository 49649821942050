import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import * as yup from 'yup';
import { FormModal } from './FormModal';
import {
  FormGrid,
  FormWithPadding,
  TextInput,
  SelectInput,
} from '../../components';
import { companyActions, resourceActions } from '../../state';
import { validateOnSubmit } from '../../lib';

const schema = yup.object().shape({
  companyIntegrationId: yup.string().required('Required'),
  residentLinkOrID: yup.string().required('Required'),
});

export function SyncResidentModal({ open, handleClose }) {
  const dispatch = useDispatch();

  const [companyIntegrations, setCompanyIntegrations] = useState([]);
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState({
    companyIntegrationId: '',
    residentLinkOrID: '',
  });

  useEffect(() => {
    (async () => {
      const response = await dispatch(companyActions.getCompanyIntegrations());
      const { data } = response;
      if (data) {
        const options = data.map((i) => ({
          id: i.id,
          name: `${i.companyName}: ${i.integrationTypeDisplay}${
            i.description ? ` - ${i.description}` : ''
          }`,
        }));
        setCompanyIntegrations(options);
      }
    })();
  }, [dispatch]);

  const onChange = useCallback((e) => {
    const { name, value } = e.target;
    setState((s) => ({ ...s, [name]: value }));
  }, []);

  const onSave = useCallback(() => {
    return validateOnSubmit({
      values: state,
      schema,
      setErrors,
    })
      .then(async () => {
        setSubmitting(true);
        const { residentLinkOrID } = state;

        const response = await dispatch(
          resourceActions.postResource({
            baseUrl: `/saas/integrations/${state.companyIntegrationId}/trigger`,
            payload: { residentLinkOrID },
            message: 'Sync triggered successfully',
          }),
        );
        setSubmitting(false);
        const { error } = response;
        if (!error) {
          handleClose();
        }
      })
      .catch((e) => console.error('error ', e));
  }, [dispatch, handleClose, state]);

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title='Sync resident'
      saveBtnText='Sync'
      submitting={submitting}
      onSave={onSave}
    >
      <FormWithPadding>
        <FormGrid container spacing={3}>
          <Grid item xs={12}>
            <SelectInput
              fullWidth
              label='Company integration'
              name={'companyIntegrationId'}
              value={state.companyIntegrationId}
              menuItems={companyIntegrations}
              schema={schema}
              error={errors.companyIntegrationId}
              onChange={onChange}
              alphabetize
            />
          </Grid>
          <Grid item xs={12} inputRow>
            <TextInput
              fullWidth
              name='residentLinkOrID'
              label='Integration link to resident'
              value={state.residentLinkOrID}
              schema={schema}
              error={errors.residentLinkOrID}
              onChange={onChange}
            />
          </Grid>
        </FormGrid>
      </FormWithPadding>
    </FormModal>
  );
}
