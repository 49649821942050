import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonProps } from '@mui/material';
import { ArrowForwardIcon } from '../../../themes';
import { useIsMobile, useIsTablet } from '../../../hooks';

export type NavButtonProps = {
  alwaysShowArrow?: boolean;
  icon?: React.ReactElement;
  label?: string | React.ReactElement;
  link: string;
  navState?: Record<string, any>;
  styles?: Record<string, any>;
  variant?: ButtonProps['variant'];
};

export function NavButton({
  alwaysShowArrow,
  icon,
  label,
  link,
  navState,
  styles = {},
  variant = 'text',
}: NavButtonProps) {
  const [hover, setHover] = useState(false);

  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  return (
    <Button
      sx={{
        borderRadius: '6px',
        lineHeight: '143%',
        justifyContent: isMobile ? 'center' : 'flex-start',
        padding: '6px 8px',
        whiteSpace: 'nowrap',
        ...styles,
      }}
      size={isTablet || isMobile ? 'small' : 'medium'}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      variant={variant}
      startIcon={icon}
      endIcon={alwaysShowArrow || hover ? <ArrowForwardIcon /> : undefined}
      component={Link}
      to={link}
      state={navState}
    >
      {label}
    </Button>
  );
}
