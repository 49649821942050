import { addDays, isPast, isWithinInterval } from 'date-fns';
import { SvgIconProps } from '@mui/material';
import { Chip } from './Chip';
import { EventIcon } from '../../themes';
import { formatDate } from '../../lib';
import { CustomTooltip } from '../ui';

export const DueDateChip = ({
  dueDate,
  dateType,
}: {
  dueDate: Date;
  dateType: string;
}) => {
  const today = new Date();
  const isOverdue = isPast(dueDate);
  const isDueSoon = isWithinInterval(dueDate, {
    start: today,
    end: addDays(today, 7),
  });

  const [typographyColor, iconColor] = isOverdue
    ? ['error.main', 'error']
    : isDueSoon
    ? ['warning.main', 'warning']
    : ['text.primary', 'disabled'];

  return (
    <CustomTooltip title={dateType}>
      <Chip
        variant='outlined'
        labelVariant='caption'
        label={formatDate(dueDate, 'MM/dd/yyyy')}
        color={typographyColor}
        Icon={EventIcon}
        iconProps={{ color: iconColor as SvgIconProps['color'] }}
        maxWidth='125px'
      />
    </CustomTooltip>
  );
};
