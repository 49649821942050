import React, { Children, isValidElement } from 'react';
import clsx from 'clsx';
import _get from 'lodash.get';
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
} from '@mui/material';

export function ListHead({
  classes,
  headerProps = {},
  numSelected,
  order,
  orderBy,
  onSelectAllClick,
  onRequestSort,
  rowCount,
  fields,
  hasBulkActions,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {hasBulkActions && (
          <TableCell padding='checkbox'>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {Children.map(fields, (field, index) => {
          return isValidElement(field) ? (
            <TableCell
              key={_get(field, 'props.source', index)}
              align={_get(field, 'props.align', 'left')}
              padding={field.props.disablePadding ? 'none' : 'normal'}
              sortDirection={
                orderBy === field.props.sortBy || orderBy === field.props.source
                  ? order
                  : false
              }
              className={clsx(classes.tableHead, field.props.headerClassName, {
                stickyCell: field.props.stickyCell,
              })}
              {...headerProps}
            >
              {field.props.sortable !== false &&
              (field.props.sortBy || field.props.source) ? (
                <TableSortLabel
                  active={
                    orderBy === field.props.sortBy ||
                    orderBy === field.props.source
                  }
                  direction={
                    orderBy === field.props.sortBy ||
                    orderBy === field.props.source
                      ? order
                      : 'asc'
                  }
                  onClick={createSortHandler(
                    field.props.sortBy || field.props.source,
                  )}
                >
                  {field.props.label}
                  {orderBy === field.props.sortBy ||
                  orderBy === field.props.source ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                <span>{field.props.label}</span>
              )}
            </TableCell>
          ) : null;
        })}
      </TableRow>
    </TableHead>
  );
}
