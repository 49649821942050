import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 77,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    position: 'relative',
    '& .MuiTableCell-root': {
      fontFamily: 'Poppins',
      '& span': {
        fontFamily: 'Poppins',
        fontSize: 14,
      },
    },
  },
  asideOpen: {
    borderRight: '1px solid theme.palette.textTheme.main',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableHead: {
    color: theme.palette.textTheme.main,
    fontSize: 14,
    fontWeight: 600,
  },
  clickableRow: {
    cursor: 'pointer',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    flex: '1 1 100%',
    textTransform: 'capitalize',
  },
}));
