import React, { memo, useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from '@mui/material';
// Local
import { AuthLayout } from './AuthLayout';
import { TextInput } from '../../components';
import {
  validateOnSubmit,
  hasAuthRequestToken,
  hasAuthRequestTokenPendingMfa,
  Routes,
} from '../../lib';
import { useDispatch, useSelector } from 'react-redux';
import { authActions, uiSelectors } from '../../state';
import { useStyles } from './Auth.styles';
import { useGetQueryParams, useUpdatePageQueryParams } from '../../hooks';

function _VerifyLoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const updateQueryParams = useUpdatePageQueryParams();
  const uiLoading = useSelector(uiSelectors.loading);
  const classes = useStyles();
  const { mfaTo, mfaType = 'email' } = useGetQueryParams();
  const [formState, setFormState] = useState({
    code: '',
    rememberMe: false,
  });
  const [errors, setErrors] = useState({});

  /** @param {SyntheticEvent<HTMLButtonElement>} e */
  async function onClickVerifyLogin(e) {
    const { code, rememberMe } = formState;
    e.preventDefault();
    validateOnSubmit({ values: formState, schema, setErrors }).then(() => {
      dispatch(authActions.verifyLogin({ code, rememberMe, navigate }));
    });
  }

  /** @param {SyntheticEvent<HTMLButtonElement>} e */
  async function onClickResendCode(e) {
    e.preventDefault();
    const response = await dispatch(authActions.sendVerificationCode());
    const { data } = response;
    if (data) {
      const { mfaInfo: { maskedSentToValue, notificationType } = {} } = data;
      updateQueryParams({
        mfaTo: maskedSentToValue,
        mfaType: notificationType,
      });
    }
  }

  const onChange = useCallback((e) => {
    const { checked, name, type, value } = e.target;
    setFormState((f) => ({
      ...f,
      [name]: type === 'checkbox' ? checked : value,
    }));
  }, []);

  useEffect(() => {
    if (hasAuthRequestToken()) {
      navigate(Routes.home.path);
    }
    if (!hasAuthRequestTokenPendingMfa()) {
      navigate(Routes.login.path);
    }
  }, [navigate]);

  return (
    <AuthLayout>
      <h1 className={clsx(classes.title, classes.welcome)}>
        2-Step verificiation
      </h1>
      <p className={classes.welcomeSubText}>
        A message with your code has been sent to your{' '}
        {mfaType?.toLowerCase() === 'email' ? 'email' : 'cell phone'}{' '}
        {mfaTo || ''}
      </p>
      <form noValidate onSubmit={onClickVerifyLogin}>
        <div className={classes.form}>
          <TextInput
            className={classes.inputSpacer}
            required
            fullWidth
            id='code'
            label='Verification Code'
            type='text'
            name='code'
            autoComplete='code'
            autoFocus
            value={formState.code}
            onChange={onChange}
            disabled={uiLoading}
            schema={schema}
            error={errors['code']}
          />
        </div>
        <div className={classes.actions}>
          <div className={classes.submitWrapper}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              className={classes.submit}
              disabled={!formState.code || uiLoading}
              onClick={onClickVerifyLogin}
              disableFocusRipple
              disableRipple
              disableTouchRipple
            >
              Verify
            </Button>
          </div>
        </div>
        <FormControlLabel
          control={
            <Checkbox
              checked={formState.rememberMe}
              name='rememberMe'
              onChange={onChange}
            />
          }
          label='Trust this device for 30 days'
        />

        <Typography className={classes.linkDescriptionText}>
          Please allow a few minutes for your verification code to be sent. If
          you have not received a code click below to send a new code.
        </Typography>
        <Typography
          className={classes.link}
          onClick={onClickResendCode}
          style={{ marginTop: '8px' }}
        >
          Resend verification code
        </Typography>
        {uiLoading && (
          <div className={classes.loader}>
            <CircularProgress size={24} />
          </div>
        )}
      </form>
    </AuthLayout>
  );
}

export const VerifyLoginPage = memo(_VerifyLoginPage);

const schema = yup.object().shape({
  code: yup
    .string('Verification code must be a string')
    .required('Verification code is required'),
});
