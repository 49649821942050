import { MouseEventHandler } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  ButtonProps,
} from '@mui/material';
import { PaperComponent } from './PaperComponent';
import { Loader } from '../ui';

type Props = {
  title: string;
  text: string;
  btnText?: string;
  btnColor?: ButtonProps['color'];
  submitting?: boolean;
  handleOk: MouseEventHandler<HTMLButtonElement>;
  handleClose: MouseEventHandler<HTMLButtonElement>;
};
export function Confirmation({
  text,
  title,
  btnText = 'Ok',
  btnColor = 'primary',
  submitting,
  handleClose,
  handleOk,
}: Props) {
  return (
    <Dialog
      open={true}
      aria-labelledby='form-dialog-title'
      fullWidth
      disableScrollLock
      PaperComponent={PaperComponent}
      // @ts-ignore // it works like this though TS is complaining
      PaperProps={{ size: 'xs' }}
    >
      <DialogTitle
        style={{ cursor: 'move', width: '100%' }}
        id='draggable-dialog'
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
        {submitting && <Loader />}
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant='contained' color={btnColor} onClick={handleOk}>
            {btnText}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
