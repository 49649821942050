import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    ...flex,
  },
  flexColumn: {
    ...flexColumn,
    width: '100%',
  },
  overviewHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: -12,
    marginBottom: 24,
    marginLeft: -24,
    marginRight: -24,
    paddingRight: 24,
    paddingLeft: 24,
    position: 'sticky',
    top: 112,
    background: '#fff',
    zIndex: 3,
  },
  overviewItem: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  pageContent: {
    minHeight: 'calc(100vh - 240px)',
    marginTop: 24,
  },
  avatar: {
    backgroundColor: '#FA595F',
    color: '#ffffff',

    fontSize: 20,
    fontWeight: 500,
    height: 53,
    width: 53,
  },
  caseNameAndFacility: {
    marginLeft: 8,
    marginRight: 12,
    ...flexColumn,
  },
  smallTitle: {
    fontSize: 20,
    fontWeight: 500,
  },
  caseName: {
    fontSize: 18,
    fontWeight: 500,
  },
  facilityName: {
    color: '#8990B6',
    marginTop: 4,
  },
  archiveButton: {
    backgroundColor: '#E5E7F5',
    minWidth: 36,
  },
  divider: {
    marginBottom: 8,
    marginRight: -24,
    marginLeft: -24,
  },
  nriLoader: {
    display: 'inline',
    padding: 8,
    marginLeft: 4,
  },
  snapshotContainer: {
    border: '1px solid #E5E7F5',
    borderRadius: 3,
    alignItems: 'flex-start',
    position: 'relative',
    zIndex: 2,
    background: '#fff',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    marginBottom: 16,
  },
  snapshotText: {
    fontSize: 12,
  },
  snapshotTextWithBadge: {
    fontSize: 12,
    // styles for the workflows badge, dont want to edit StatusField component because used all over - needed for responsive
    '& > div': {
      marginLeft: '16px !important',
    },
    '& > span': {
      left: 0,
    },
  },
  fillerSnapshotItem: {
    width: 260,
  },
  snapshotItem: {
    ...flexColumn,
    padding: '24px 24px 0 24px',
  },
  snapshotItemWithDivider: {
    height: '100%',
    display: 'flex',
    padding: '24px 24px 0 24px',
  },
  snapshotDivider: {
    width: 1,
    backgroundColor: '#E5E7F5',
    marginRight: 24,
    marginBottom: 16,
  },
  snapshotItemBadge: {
    left: 0,
  },
  snapshotItemTitle: {
    marginBottom: 16,
    color: '#8990B6',
    textTransform: 'Uppercase',
  },
  caseDetailsTabs: {
    marginLeft: -24,
    marginRight: -24,
    paddingRight: 24,
    width: 'calc(100vw - 128px)',
    position: 'sticky',
    top: 164,
    background: '#fff',
    zIndex: 3,
    '& .MuiTab-wrapper': {
      fontSize: 14,
    },
  },
  currentPayerAuth: {
    paddingTop: '16px',
    display: 'flex',
    alignItems: 'center',
  },
  currentPayerAuthText: { color: '#8990B6' },
  currentPayerAuthIconRed: {
    ...currentPayerAuthIcon,
    color: '#F91429',
  },
  currentPayerAuthIconGreen: {
    ...currentPayerAuthIcon,
    color: '#0FC076',
  },
  snapshotLink: {
    color: '#096EF8',
    fontWeight: 500,
    textTransform: 'capitalize',
    marginLeft: 4,
    cursor: 'pointer',
  },
  reverseNRIButton: {
    padding: 2,
    color: '#096EF8',
    marginLeft: 2,
  },
  caseworkerDisplay: {
    ...flex,
    fontSize: 14,
    border: 'solid 1px #E5E7F5',
    borderRadius: 3,
    padding: 8,
    marginRight: 8,
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
  },
  caseworkerIcon: {
    fontSize: 18,
    marginRight: 10,
  },
}));

const flex = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const flexColumn = {
  display: 'flex',
  flexDirection: 'column',
};

const currentPayerAuthIcon = {
  fontSize: '16px',
  marginLeft: '6px',
  display: 'flex',
  alignItems: 'center',
};
