import { useCallback, useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { parse as parseContentDisposition } from 'content-disposition-header';
import { object, string, array } from 'yup';
import { FormModal } from './FormModal';
import { AutocompleteInput } from '../common/inputs';
import { useNotify } from '../../hooks';
import { documentActions } from '../../state';
import {
  validateOnSubmit,
  authPost,
  downloadFileFromResponse,
} from '../../lib';
import { DocumentViewModal } from './DocumentViewModal';
import { NameWithAvatar } from '../name';

export function PrintForm({
  open,
  handleClose,
  residentId,
  quickActionCaseName = '',
}) {
  const dispatch = useDispatch();
  const notify = useNotify();
  const [viewOpen, setViewOpen] = useState(false);
  const [file, setFile] = useState({});
  const [generating, setGenerating] = useState(false);
  const [errors, setErrors] = useState({});
  const [documents, setDocuments] = useState([]);
  const [state, setState] = useState({
    docTypes: [],
    residentId,
  });

  useEffect(() => {
    setState({
      residentId,
      docTypes: [],
    });
  }, [residentId]);

  useEffect(() => {
    async function getDocs(residentId) {
      const response = await dispatch(
        documentActions.getDocGenConfigs({ residentId }),
      );
      const docsSorted = [...response.data].sort((m1, m2) =>
        m1.name.localeCompare(m2.name),
      );
      setDocuments(docsSorted);
    }

    getDocs(state.residentId);
  }, [state.residentId, dispatch]);

  const onPrint = useCallback(async () => {
    return validateOnSubmit({
      values: state,
      schema,
      setErrors,
    })
      .then(async () => {
        setGenerating(true);
        const response = await authPost(
          ['/docs/gen/run'],
          {
            residentId: state.residentId,
            docGenConfigIDs: state.docTypes,
          },
          undefined,
          undefined,
          {
            responseType: 'blob', //important
          },
        );
        setGenerating(false);
        const { data, error, headers } = response;
        if (error)
          return notify(
            error.message || 'Error printing the document',
            'error',
          );
        if (data) {
          //if we requested more than 1 doc, its a zip, just download. else preview
          if (state.docTypes.length > 1) {
            const parsedValue = parseContentDisposition(
              headers['content-disposition'],
            );
            const filename = parsedValue.parameters?.filename;
            downloadFileFromResponse(data, filename);
            handleClose();
          } else {
            const fileUrl = window.URL.createObjectURL(
              new Blob([data], { type: 'application/pdf' }),
            );

            setFile({ previewURL: fileUrl });
            setViewOpen(true);
          }
        }
      })
      .catch((e) => console.error('error ', e));
  }, [state, notify, handleClose]);

  const onChange = useCallback((event) => {
    const { value, name } = event.target;
    setState((curState) => ({ ...curState, [name]: value }));
  }, []);

  return (
    <FormModal
      handleClose={handleClose}
      open={open}
      title='Print Document'
      onSave={onPrint}
      saveBtnText='Print'
      submitting={generating}
      size='sm'
      rightSideHeader={
        <Box py={2}>
          <NameWithAvatar personName={quickActionCaseName} />
        </Box>
      }
    >
      <Box p={3}>
        <Grid container rowSpacing={2} direction='column'>
          <Grid item xs={12}>
            <Typography color={'text.secondary'} ml={1}>
              Editable PDF forms are created using resident data in Aidace.
              <br /> When there is no information, forms default to the "no"
              selections.
              <br /> You may make any necessary additions, edits, or deletions
              for accuracy.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <AutocompleteInput
              fullWidth
              multiple
              textFieldProps={{
                label: 'Select Document',
                onChange: onChange,
                required: true,
              }}
              autocompleteProps={{
                options: documents,
                value: state.docTypes,
                name: 'docTypes',
                disableCloseOnSelect: true,
              }}
              schema={schema}
              error={errors.docTypes}
            />
          </Grid>
        </Grid>
      </Box>

      {viewOpen && (
        <DocumentViewModal
          open={viewOpen}
          handleClose={() => {
            setViewOpen(false);
            handleClose();
          }}
          file={file}
        />
      )}
    </FormModal>
  );
}

const schema = object().shape({
  docTypes: array(string())
    .typeError('must be an array')
    .required('Document is a required field')
    .min(1, 'At least one document is required')
    .max(10, 'the maximum number of the docs to print at a time is 10'),
  residentId: string('This field must be a string')
    .nullable()
    .required('required'),
});
