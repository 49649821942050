export const styles = (theme) => ({
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  actions: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 7,
    marginTop: 20,
    marginBottom: 20,
  },
  title: {
    textTransform: 'capitalize',
    fontSize: 18,
    fontWeight: 600,
  },
  multilineField: {
    display: 'flex',
    flexDirection: 'column',
  },
  boldField: {
    color: '#000000',
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  editIcon: {
    color: theme.palette.outlineTheme.main,
    cursor: 'pointer',
  },
});
