import React, { useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { reach } from 'yup';
import { TextField } from '@mui/material';
import { CalendarOutlinedIcon } from '../../../themes';
import { useStyles } from './inputs.styles';

const CustomDatePicker = React.forwardRef((props, ref) => {
  const { minDate, onChange, value, ...other } = props;

  // not sure exactly what this does - getting warnings without it (CG)
  // link to mui docs about 3rd party integration https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
  // implement `InputElement` interface
  React.useImperativeHandle(ref, () => ({
    focus: () => {
      // logic to focus the rendered component from 3rd party belongs here
    },
  }));

  return (
    <DatePicker
      {...other}
      customInputRef={ref()}
      minDate={
        minDate
          ? typeof minDate === 'string'
            ? new Date(minDate)
            : minDate
          : null
      }
      onChange={(value) => {
        onChange({
          target: {
            name: props.name,
            value: value,
          },
        });
      }}
      selected={value}
      showMonthDropdown
      showYearDropdown
      scrollableYearDropdown
      popperProps={{ strategy: 'fixed' }}
      popperPlacement='bottom-start'
    />
  );
});

export function DateInput({ schema, nestedKey, ...props }) {
  const classes = useStyles();
  const {
    className,
    placeholder,
    fullWidth,
    onChange,
    name,
    value,
    error,
    addHelperTextStyle = true,
    context,
    inputVariant = 'outlined',
    isDateOnly = true,
    useSetTime = false, //if false, it will use current time. if true it will use a set time in current timezone (Default is 12AM, override this with the hr+min props). This prop is only relevant if `isDateOnly` is false
    hr = 0,
    min = 0,
    minDate,
    required,
    ...otherProps
  } = props;
  const [isDirty, setIsDirty] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    setErrorMessage(error);
    if (error) {
      setIsDirty(true);
    }
  }, [error]);

  useEffect(() => {
    // this is for when the validation is based of of another field
    // and the values of the context is true/false for example the pasRequested field on facilityIntake
    if (context) {
      const valuesArr = Object.keys(context).reduce((acc, cur) => {
        acc.push(context[cur]);
        return acc;
      }, []);
      if (valuesArr.every((v) => v === false)) {
        setErrorMessage(undefined);
      }
    }
  }, [context]);

  const handleBlur = useCallback(() => {
    // if there is already an error message let the onchange handle the validation
    if (schema && !errorMessage) {
      setIsDirty(true);
      reach(schema, nestedKey || name)
        .validate(value, { context })
        .then(() => setErrorMessage(undefined))
        .catch((err) => setErrorMessage(err.errors?.[0]));
    }
  }, [context, errorMessage, name, nestedKey, schema, value]);

  const handleChange = useCallback(
    (event) => {
      const { name, value } = event.target || {};
      const updatedValue = value
        ? isDateOnly
          ? jsDateToDateOnlyString(value) // MM/DD/YYYY
          : useSetTime
          ? getSelectedDateAtTime(value, hr, min) //explicitly set time in the case if the time was set previously, selecting a new date would keep the old time (since there is no timepicker)
          : getSelectedDateWithCurrentTime(value)
        : null;

      const updatedEvent = {
        target: { name: props.name, value: updatedValue },
      };
      typeof onChange === 'function' && onChange(updatedEvent);

      if (schema && isDirty) {
        reach(schema, nestedKey || name)
          .validate(value, { context })
          .then(() => setErrorMessage(undefined))
          .catch((err) => setErrorMessage(err.errors?.[0]));
      }
    },
    [
      context,
      isDirty,
      nestedKey,
      onChange,
      props.name,
      schema,
      isDateOnly,
      useSetTime,
      hr,
      min,
    ],
  );

  return (
    <TextField
      InputProps={{
        inputComponent: CustomDatePicker,
        inputProps: {
          minDate: minDate,
        },
        classes: {
          root: classes.inputRoot,
          focused: classes.focused,
          disabled: classes.disabled,
          error: classes.error,
          input: classes.input,
          notchedOutline: classes.notchedOutline,
        },
        endAdornment: <CalendarOutlinedIcon sx={{ color: '#8990B6' }} />,
        notched: value ? true : false,
      }}
      InputLabelProps={{
        classes: {
          root: classes.label,
          focused: classes.focused,
          error: classes.error,
          disabled: classes.disabled,
        },
        shrink: value ? true : false,
      }}
      fullWidth={fullWidth}
      name={name}
      value={
        value ? (typeof value === 'string' ? new Date(value) : value) : null
      }
      className={clsx({ [classes.root]: !fullWidth }, className)}
      onChange={handleChange}
      variant={inputVariant}
      onBlur={handleBlur}
      error={!!errorMessage}
      helperText={errorMessage}
      required={required}
      FormHelperTextProps={{
        classes: {
          root: clsx({ [classes.helperText]: addHelperTextStyle }),
        },
      }}
      {...otherProps}
    />
  );
}

// Needed because of https://github.com/mui-org/material-ui-pickers/issues/1526
function jsDateToDateOnlyString(date) {
  return [
    String(101 + date.getMonth()).substring(1),
    String(100 + date.getDate()).substring(1),
    String(date.getFullYear()),
  ].join('/');
}

function getSelectedDateWithCurrentTime(dt) {
  const today = new Date();
  const date = dt.getDate();
  const month = dt.getMonth();
  const year = dt.getFullYear();

  //we first set to the first day of the current month so it doesnt jump if any of the later month/day/year configurations are invalid (i.e. if today is feb 29, any month 31st etc)
  today.setDate(1);

  //then set the value that the user chose
  today.setFullYear(year);
  today.setMonth(month);
  today.setDate(date);

  return today;
}

function getSelectedDateAtTime(date, hr = 0, min = 0, sec = 0, ms = 0) {
  return new Date(date.setHours(hr, min, sec, ms));
}

// #region Typedefs
/** @typedef {object} dateInputProps
 * @property {Object} [context] An object with the name and value of the field to use as Yup context
 * @property {Object} schema The defined Yup schema
 * @property {string} [nestedKey] If the value is a nested value provide the path. Example nestedKey='residentContacts.firstName'.
 * @property {string} error The error message when failing validation on submit
 * @property {boolean} [addHelperTextStyle] Boolean to add position absolute to helper text. Defaults to true.
 * @property {boolean} isDateOnly Flag to know whether the value returned should be a date only string or a valid date with time (will be the current time)
 */
// #endregion
