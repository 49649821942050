import { Tabs, Tab, TabsProps } from '@mui/material';
import { useStyles } from './actionsTabs.styles';
import { CommonProps } from '@mui/material/OverridableComponent';
import { ReactNode } from 'react';

type Props = {
  tabs: TabType[];
  tabPosition: any;
  onClick: TabsProps['onChange'];
  style?: CommonProps['style'];
  variant?: TabsProps['variant'];
};
export function ActionsTabs({
  tabs,
  tabPosition,
  onClick,
  style,
  variant,
}: Props) {
  const classes = useStyles();

  return (
    <Tabs
      //if tabPosition is undefined, set value to false to turn off the errors that its not a valid value.  There will just be no selected tab
      value={tabPosition || false}
      onChange={onClick}
      classes={{ indicator: classes.indicator }}
      style={style}
      variant={variant || 'standard'}
    >
      {tabs.map((t, i) => {
        const { name, value, disabled } = t;
        return (
          <Tab
            key={i}
            value={value}
            label={name}
            disabled={disabled}
            disableFocusRipple
            disableRipple
            disableTouchRipple
            classes={{
              root: classes.root,
              selected: classes.selected,
            }}
          />
        );
      })}
    </Tabs>
  );
}

type TabType = {
  name: string | ReactNode;
  value: any;
  disabled?: boolean;
};
