import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Button,
} from '@mui/material';
import { CustomTooltip, FlexBetween, Loader } from '../ui';
import { PaperComponent } from '../modals/PaperComponent';
import { CancelBtn, DeleteBtn } from '../../components';
import { useStyles } from './formModal.styles';

export function FormModal(props) {
  const {
    children,
    disableSave,
    handleClose,
    onSave,
    open,
    title,
    isEdit,
    handleDelete,
    saveBtnText = 'Save',
    saveBtnStyle,
    paperProps,
    footerElement,
    submitting,
    hideDeleteBtn = false,
    rightSideHeader,
    hideFooter = false,
    hide,
    size,
    errorMessage,
    noSubmit = false,
    secondarySaveBtn = null,
    deleteMsg,
    deleteTitle,
    disableEnforceFocus = false,
    disableScrollContent = false,
    disableDeleteButtonReason,
  } = props;

  const classes = useStyles();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const deleteButton =
    isEdit && !hideDeleteBtn ? (
      <CustomTooltip title={disableDeleteButtonReason}>
        <DeleteBtn
          onClick={() => setShowDeleteConfirm(true)}
          disabled={submitting || !!disableDeleteButtonReason}
        >
          Delete
        </DeleteBtn>
      </CustomTooltip>
    ) : null;

  return (
    <Dialog
      open={open}
      fullWidth={true}
      PaperComponent={PaperComponent}
      PaperProps={{ ...paperProps, size: showDeleteConfirm ? 'xs' : size }}
      style={hide ? { display: 'none' } : null}
      disableEnforceFocus={disableEnforceFocus}
    >
      {showDeleteConfirm ? (
        <DeleteContent
          handleClose={() => setShowDeleteConfirm(false)}
          title={deleteTitle || title}
          deleteMsg={deleteMsg}
          handleDelete={handleDelete}
          submitting={submitting}
        />
      ) : (
        <>
          <div
            className={
              disableScrollContent ? undefined : classes.scrollableContent
            }
          >
            <FlexBetween paddingX={3}>
              <Box
                paddingY={2}
                className={classes.sectionHeaderApplicant}
                style={{
                  flexGrow: 1,
                  cursor: 'move',
                }}
                id='draggable-dialog'
              >
                {title}
              </Box>
              <Box>{rightSideHeader && <span>{rightSideHeader}</span>}</Box>
            </FlexBetween>
            <div className={classes.content}>
              <form
                autoComplete='off'
                onSubmit={noSubmit ? (e) => e.preventDefault() : undefined}
              >
                {children}
              </form>
            </div>
          </div>
          {!hideFooter && (
            <>
              <Divider className={classes.headerDivider} />
              {submitting && <Loader />}
              <DialogActions
                style={{
                  padding: '16px 24px',
                  justifyContent: 'space-between',
                }}
              >
                {footerElement || deleteButton || <div />}
                <div>
                  {footerElement && deleteButton}
                  <CancelBtn
                    color='primary'
                    variant='contained'
                    onClick={handleClose}
                    disabled={submitting}
                    style={{ marginLeft: 20 }}
                  >
                    Cancel
                  </CancelBtn>

                  <Button
                    color='primary'
                    variant='contained'
                    onClick={onSave}
                    style={{
                      marginLeft: 20,
                      minWidth: 90,
                      ...(saveBtnStyle || {}),
                    }}
                    disabled={submitting || !!disableSave}
                  >
                    {saveBtnText}
                  </Button>
                  {secondarySaveBtn}
                  {errorMessage && (
                    <div className={classes.errorMessage}>{errorMessage}</div>
                  )}
                </div>
              </DialogActions>
            </>
          )}
        </>
      )}
    </Dialog>
  );
}

export function DeleteContent({
  title,
  deleteMsg = 'Are you sure you want to delete?',
  handleDelete,
  handleClose,
  submitting,
}) {
  const classes = useStyles();
  return (
    <div>
      <DialogTitle
        style={{ cursor: 'move', width: '100%' }}
        id='draggable-dialog'
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText color='textPrimary'>{deleteMsg}</DialogContentText>
        {submitting && <Loader />}
        <DialogActions>
          <CancelBtn
            color='primary'
            variant='contained'
            onClick={handleClose}
            disabled={submitting}
          >
            Cancel
          </CancelBtn>
          <Button
            onClick={handleDelete}
            disabled={submitting}
            variant='contained'
            className={classes.confirmDeleteBtn}
            style={{
              marginLeft: 20,
            }}
          >
            Yes, Delete It
          </Button>
        </DialogActions>
      </DialogContent>
    </div>
  );
}
