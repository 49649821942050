import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import _isEqual from 'lodash.isequal';
import {
  Box,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
  Avatar as MaterialAvatar,
  Tooltip,
  IconButton,
  DialogActions,
  Switch,
} from '@mui/material';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  GetListsForCategory,
  GetListSettingName,
} from '../../main/ListViews/helpers/ListHelpers';
import {
  Avatar,
  FlexBetween,
  Loader,
  ReadOnlyComponent,
  ColoredIcon,
} from '../../../components';
import { useGetUsersData, useNotify } from '../../../hooks';
import { DragHandleIcon, StarIcon } from '../../../themes';
import StarBorder from '@mui/icons-material/StarBorder';
import { settingActions } from '../../../state';
import { companyTypes, ListCategoryTypes } from '../../../lib';
import Prompt from '../../../lib/routing/Prompt';
import { CancelBtn } from '../../../components';

export function ListSettingsPage() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const notify = useNotify();

  const { companyType } = useGetUsersData();

  //drag n drop sensors
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const [category, setCategory] = useState(ListCategoryTypes.Resident);
  const [submitting, setSubmitting] = useState(false);
  const [initialListSettings, setInitialListSettings] = useState([]);
  const [listSettings, setListSettings] = useState([]);

  const visibleListSettings = useMemo(
    () => listSettings.filter((l) => l.isVisible),
    [listSettings],
  );

  const sortedVisibleListSettings = useMemo(
    () =>
      visibleListSettings //sort visible by sort order
        .sort((o1, o2) => {
          return o1.sortOrder - o2.sortOrder;
        }),
    [visibleListSettings],
  );
  const itemIds = useMemo(
    () => sortedVisibleListSettings.map((l) => l.key),
    [sortedVisibleListSettings],
  );

  const nonVisibleListSettings = useMemo(
    () => listSettings.filter((l) => !l.isVisible),
    [listSettings],
  );

  const getListSettings = useCallback(async () => {
    const response = await dispatch(
      settingActions.getCompanyListSettings(category),
    );
    const { data } = response;
    if (data) {
      const mapped = data
        .filter((l) => {
          const list = GetListsForCategory(category)[l.type];
          //filter out any lists that are not applicable for this company type
          return (
            !l.isSystemView ||
            !list?.companyType ||
            list?.companyType === companyType
          );
        })
        .map((l, i) => {
          //pull in the names for the system views from our config files
          const displayName = GetListSettingName(l, category, companyType);
          //also save a key so we can use for identifying edits since they don't necessarily all have ids/listPreferenceIds. This needs to be a string for the sortable animation to work.
          return { ...l, displayName, key: i.toString() };
        });
      setInitialListSettings(mapped);
      setListSettings(mapped);
    }
  }, [category, dispatch, companyType]);

  const onChangeCategory = useCallback((_, newValue) => {
    if (newValue !== null) {
      setCategory(newValue);
    }
  }, []);

  const handleDragEnd = useCallback(
    (event) => {
      const { active, over } = event;
      if (active.id !== over.id) {
        const oldIndex = sortedVisibleListSettings.findIndex(
          (l) => l.key === active.id,
        );
        const newIndex = sortedVisibleListSettings.findIndex(
          (l) => l.key === over.id,
        );
        const orderedFields = arrayMove(
          sortedVisibleListSettings,
          oldIndex,
          newIndex,
        );
        setListSettings([
          ...orderedFields.map((l, i) => ({ ...l, sortOrder: i + 1 })),
          ...nonVisibleListSettings,
        ]);
      }
    },
    [sortedVisibleListSettings, nonVisibleListSettings],
  );

  const onClickMarkAsDefault = useCallback((setting) => {
    const clearOutOthers = !setting.isDefault;
    setListSettings((s) =>
      s.map((l, i) => {
        const settingCopy = { ...l };
        if (l.key === setting.key) {
          settingCopy.isDefault = !setting.isDefault;
        } else if (clearOutOthers) {
          settingCopy.isDefault = false;
        }
        return settingCopy;
      }),
    );
  }, []);

  const onChangeIsVisible = useCallback(
    (setting) => {
      setListSettings((s) => {
        const updated = [...s];
        const index = updated.findIndex((l) => l.key === setting.key);
        if (setting.isVisible) {
          updated[index] = {
            ...updated[index],
            isVisible: false,
            isDefault: false,
          };
        } else {
          updated[index] = {
            ...updated[index],
            isVisible: true,
            sortOrder:
              (sortedVisibleListSettings[sortedVisibleListSettings.length - 1]
                ?.sortOrder ?? 0) + 1,
          };
        }
        return updated;
      });
    },
    [sortedVisibleListSettings],
  );

  const onCancel = useCallback(() => {
    setListSettings(initialListSettings);
  }, [initialListSettings]);

  const onSave = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      settingActions.saveCompanyListSettings(category, listSettings),
    );
    setSubmitting(false);
    if (response.data) {
      notify('Changes saved');
      getListSettings();
    }
  }, [dispatch, category, listSettings, notify, getListSettings]);

  useEffect(() => {
    getListSettings();
  }, [category, getListSettings]);

  const getToggleButton = useCallback(
    (listType) => (
      <ToggleButton key={listType} value={listType}>
        {listType === ListCategoryTypes.Resident &&
        companyType === companyTypes.Application
          ? 'Client'
          : listType}{' '}
        lists
      </ToggleButton>
    ),
    [companyType],
  );

  return (
    <div>
      <Box sx={{ fontSize: 18, fontWeight: 600, mt: 4 }}>List settings</Box>
      <Box sx={{ color: theme.palette.text.secondary, fontSize: 14, mt: 1 }}>
        Activate and reorder your company lists. Star a list to make it the
        default. To create a new list, save your desired filters as a company
        list on the list screen and then activate it here. Note: Your personal
        lists do not appear here.
      </Box>
      <ReadOnlyComponent
        isReadOnly={!_isEqual(listSettings, initialListSettings)}
        message='Submit your unsaved changes in order to toggle between the lists.'
      >
        <ToggleButtonGroup
          exclusive
          onChange={onChangeCategory}
          value={category}
          color={'primary'}
          sx={{
            mt: 2,
            mb: 3,
            '.MuiToggleButton-root': {
              color: theme.palette.text.secondary,
              textTransform: 'none',
              fontWeight: 'unset',
              '&.Mui-selected': {
                border: '1px solid #096EF8',
                color: '#000000',
                backgroundColor: 'rgba(9,110,248,0.12)',
              },
            },
          }}
        >
          {getToggleButton(ListCategoryTypes.Resident)}
          {getToggleButton(ListCategoryTypes.Application)}
        </ToggleButtonGroup>
      </ReadOnlyComponent>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
          {sortedVisibleListSettings.map((l, i) => {
            return (
              <ListSettingItem
                key={i}
                setting={l}
                onClickMarkAsDefault={onClickMarkAsDefault}
                onChangeIsVisible={onChangeIsVisible}
              />
            );
          })}
        </SortableContext>
      </DndContext>
      {nonVisibleListSettings //sort non-visible alphabetically
        .sort((o1, o2) => {
          return o1.displayName > o2.displayName ? 0 : -1;
        })
        .map((l, i) => {
          return (
            <ListSettingItem
              key={i}
              setting={l}
              onChangeIsVisible={onChangeIsVisible}
            />
          );
        })}

      {submitting && <Loader />}
      <DialogActions
        sx={{
          pt: 3,
          justifyContent: 'flex-start',
        }}
      >
        <CancelBtn
          onClick={onCancel}
          disabled={submitting}
          variant={'contained'}
        >
          Cancel
        </CancelBtn>
        <Button
          color='primary'
          variant='contained'
          onClick={onSave}
          disabled={submitting}
        >
          Save
        </Button>
      </DialogActions>
      <Prompt
        when={!_isEqual(listSettings, initialListSettings)}
        message={'You have unsaved changes that will be lost.'}
      />
    </div>
  );
}

function ListSettingItem({ setting, onClickMarkAsDefault, onChangeIsVisible }) {
  const {
    displayName,
    createdByUser,
    isDefault,
    isVisible,
    isSystemView,
    key,
  } = setting;
  const theme = useTheme();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: key, disabled: !isVisible });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <FlexBetween
      align='center'
      sx={{
        border: '1px solid #D5D8EC',
        borderRadius: '3px',
        p: 2,
        mb: 1,
        background: '#fff',
      }}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <FlexBetween align='center'>
        <Box sx={{ fontSize: 16, fontWeight: 600 }}>{displayName}</Box>
        {isVisible && (
          <Tooltip title={'Set as default'} placement='top'>
            <IconButton
              sx={{ p: 0, ml: 2 }}
              onClick={() => onClickMarkAsDefault(setting)}
            >
              {isDefault ? (
                <ColoredIcon Icon={StarIcon} iconColor='primary.light' />
              ) : (
                <ColoredIcon Icon={StarBorder} iconColor='text.secondary' />
              )}
            </IconButton>
          </Tooltip>
        )}
      </FlexBetween>

      <FlexBetween align='center'>
        <Tooltip title={createdByUser} placement={'top'}>
          {isSystemView ? (
            <MaterialAvatar
              sx={{
                height: 24,
                width: 24,
                backgroundColor: '#F5F6FE',
                padding: 0.5,
              }}
              src={'/images/aidace-logo-icon.svg'}
            />
          ) : (
            <span>
              <Avatar
                sx={{ height: 24, width: 24, fontSize: 10, fontWeight: 500 }}
                name={createdByUser}
                color={theme.palette.primary.light}
              />
            </span>
          )}
        </Tooltip>
        <Tooltip
          title={`${isVisible ? 'Turn off' : 'Activate'} for all users`}
          placement='top'
        >
          <Switch
            sx={{
              ml: 2.5,
            }}
            checked={isVisible}
            onChange={() => onChangeIsVisible(setting)}
          />
        </Tooltip>
        <Box
          sx={{
            display: 'flex',
            borderLeft: '1px solid #D5D8EC',
            px: 3,
            py: 3,
            my: -2.5,
            mr: -2,
          }}
        >
          <Tooltip title={isVisible ? 'Drag to reorder' : null}>
            <DragHandleIcon
              sx={{
                color: theme.palette.text.secondary,
                ':hover': { cursor: isVisible ? 'grab' : undefined },
              }}
              {...listeners}
            />
          </Tooltip>
        </Box>
      </FlexBetween>
    </FlexBetween>
  );
}
