export const sharedFormStyles = (theme) => ({
  // TODO CG: move avatar styles into FormStyles.tsx as styled components
  avatar: {
    fontSize: 12,
    fontWeight: 500,
    height: 30,
    width: 30,
  },
  avatarAssignee: {
    backgroundColor: theme.palette.primary.light,
    color: '#ffffff',
  },
  avatarMe: {
    backgroundColor: '#0F0190',
    color: 'ffffff',
  },

  // TODO CG: move chips styles into FormStyles.tsx as styled components
  chips: {
    //margin: 12,
    marginTop: 12,
    marginRight: 10,
    background: '#E5E7F5',
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Roboto',
    fontSize: 14,
  },

  // TODO CG: replace pageSmallTitle class with <Typography color="#000000" fontSize={16} fontWeight={500}> (one day...)
  pageSmallTitle: {
    color: '#000000',
    fontSize: 16,
    fontWeight: 500,
  },

  // TODO CG: replace flex class with <Box display="flex"> (one day...)
  flex: {
    ...flex,
  },
});

const flex = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
