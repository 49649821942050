import { useEffect, useMemo, useState } from 'react';
import { IdName } from '../lib';

interface IdNameValidity extends IdName {
  isNotValid?: boolean;
}

export function useListWithInvalidSelection(
  validValues: IdName[],
  selectedValues: IdName[] | IdName,
  invalidSuffix: string = ' (Invalid User)',
) {
  const [inclusiveList, setInclusiveList] =
    useState<IdNameValidity[]>(validValues);

  const selectedValuesArray: IdName[] = useMemo(() => {
    return !selectedValues
      ? []
      : Array.isArray(selectedValues)
      ? selectedValues
      : [selectedValues];
  }, [selectedValues]);

  useEffect(() => {
    const invalidSelection = selectedValuesArray.filter(
      (s) => !validValues.some((i) => i.id === s.id),
    );
    if (invalidSelection.length) {
      setInclusiveList(() => {
        const listCopy = [...validValues];
        listCopy.unshift(
          ...invalidSelection.map((i) => ({
            ...i,
            isNotValid: true,
            name: i.name + invalidSuffix,
          })),
        );
        return listCopy;
      });
    } else {
      setInclusiveList(validValues);
    }
  }, [validValues, selectedValuesArray, invalidSuffix]);

  useEffect(() => {
    const invalidItemsInListThatAreNotSelected = inclusiveList.filter(
      (i) => i.isNotValid && !selectedValuesArray.some((s) => s.id === i.id),
    );

    if (invalidItemsInListThatAreNotSelected.length) {
      setInclusiveList((prevList) =>
        prevList.filter(
          (l) =>
            !invalidItemsInListThatAreNotSelected.some((i) => i.id === l.id),
        ),
      );
    }
  }, [inclusiveList, selectedValuesArray]);

  return inclusiveList;
}
