import React from 'react';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import {
  AutocompleteInput,
  CheckboxInput,
  DateInput,
  RadioInput,
  SelectInput,
  TextInput,
} from './';

export function DynamicFormField({
  classes,
  displayName,
  errors,
  field,
  getContext = () => {},
  inputType,
  isFullWidth = false,
  isNote: _isNote = false,
  isRequired = false,
  menuItems = [],
  onChange,
  options = [],
  schema,
  state,
  shouldHideField = () => {},
  shouldDisableField = () => {},
  fieldTag,
}) {
  //going forward we will use the type of Textarea for note fields. for existing ones it is still using String type and passing in isNote=true
  const isNote = _isNote || inputType === 'Textarea';

  return (
    <>
      {!!inputType && (
        <Grid
          item
          xs={isNote || inputType === 'Radio' || isFullWidth ? 12 : 6}
          className={clsx({
            [classes.gridFullWidthItem]:
              inputType === 'Radio' || isFullWidth || isNote,
            [classes.displayNone]: shouldHideField(field, state),
          })}
        >
          {inputType === 'Date' && (
            <DateInput
              fullWidth
              disabled={shouldDisableField(field, state)}
              value={state[field]}
              name={field}
              onChange={onChange}
              label={displayName}
              schema={schema}
              error={errors[field]}
              required={isRequired}
              context={{
                [`isRequired${field}`]: isRequired,
                ...getContext(field, state),
              }}
              sx={{ width: '100%' }}
            />
          )}
          {(inputType === 'String' || inputType === 'Textarea') && (
            <TextInput
              fullWidth
              disabled={shouldDisableField(field, state)}
              value={state[field] || ''}
              name={field}
              onChange={onChange}
              label={displayName}
              schema={schema}
              error={errors[field]}
              required={isRequired}
              multiline={isNote}
              rows={isNote ? 2 : undefined}
              context={{
                [`isRequired${field}`]: isRequired,
                ...getContext(field, state),
              }}
            />
          )}
          {inputType === 'Select' && (
            <SelectInput
              fullWidth
              disabled={shouldDisableField(field, state)}
              value={state[field] || ''}
              name={field}
              onChange={onChange}
              menuItems={menuItems}
              label={displayName}
              schema={schema}
              error={errors[field]}
              required={isRequired}
              context={{
                [`isRequired${field}`]: isRequired,
                ...getContext(field, state),
              }}
            />
          )}
          {inputType === 'Checkbox' && (
            <div className={classes.inputRow}>
              <CheckboxInput
                checkboxes={[
                  {
                    labelProps: {
                      label: displayName,
                    },
                    checkboxProps: {
                      name: field,
                      checked: state[field],
                      disabled: shouldDisableField(field, state),
                      onChange: (e) => onChange(e, state),
                      error: errors[field],
                      required: isRequired,
                      context: {
                        [`isRequired${field}`]: isRequired,
                        ...getContext(field, state),
                      },
                    },
                  },
                ]}
                schema={schema}
              />
            </div>
          )}
          {inputType === 'Autocomplete' && (
            <AutocompleteInput
              fullWidth
              textFieldProps={{
                disabled: shouldDisableField(field, state),
                label: displayName,
                onChange: onChange,
                required: isRequired,
              }}
              autocompleteProps={{
                className: classes.inputRow,
                disabled: shouldDisableField(field, state),
                options: options[field],
                value: state[field]
                  ? options[field].find((f) => f.id === parseInt(state[field]))
                  : null,
                name: field,
              }}
              schema={schema}
              error={errors[field]}
              context={{
                [`isRequired${field}`]: isRequired,
                ...getContext(field, state),
              }}
            />
          )}
          {inputType === 'Radio' && (
            <RadioInput
              fullWidth
              disabled={shouldDisableField(field, state)}
              value={state[field]}
              name={field}
              onChange={onChange}
              radios={menuItems?.map((m) => ({
                label: m.name,
                value: m.id.toString(),
              }))}
              label={displayName}
              labelProps={{
                style: {
                  fontSize: 18,
                  color: '#000000',
                },
              }}
              schema={schema}
              error={errors[field]}
              required={isRequired}
              row={false}
              context={{
                [`isRequired${field}`]: isRequired,
                ...getContext(field, state),
              }}
            />
          )}
          {!!fieldTag && fieldTag}
        </Grid>
      )}
    </>
  );
}
