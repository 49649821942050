import React from 'react';
import { Avatar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { sharedFormStyles } from '../modals/sharedStyles';

export function NameWithAvatar({ personName }) {
  const useStyles = makeStyles((theme) => ({
    ...sharedFormStyles(theme),
  }));
  const classes = useStyles();
  return personName ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Avatar
        className={classes.avatar}
        sx={{ backgroundColor: '#FA595F', color: '#ffffff', cursor: 'pointer' }}
      >
        {`${personName?.split(' ')[0]?.charAt(0)?.toUpperCase() || ''}${
          personName?.split(' ')[1]?.charAt(0)?.toUpperCase() || ''
        }`}
      </Avatar>
      <span
        style={{
          marginLeft: 10,
          fontWeight: '500',
          fontSize: 16,
        }}
      >
        {personName}
      </span>
    </div>
  ) : null;
}
