import { generatePath as generatePathRouter } from 'react-router-dom';
import { stringify } from 'query-string';

import { CompanyUserAccessTypes, NoCaseId } from './constants';
import { CaseIdType, User } from './types';

export const IS_NOT_PROD = process.env.REACT_APP_ENV !== 'production';

export function isCaseIdValid(caseId: CaseIdType) {
  return caseId !== NoCaseId;
}

export function safeIncrement(obj: Record<any, number>, key: any) {
  if (!obj[key]) {
    obj[key] = 1;
  } else {
    obj[key] += 1;
  }
}

export function generatePath(
  path: string,
  pathParams?: Record<string, any>,
  queryParams?: Record<string, any>,
) {
  const generatedPath = generatePathRouter(path, pathParams);
  const queryParamsStr = queryParams && stringify(queryParams);
  return `${generatedPath}${queryParamsStr ? `?${queryParamsStr}` : ''}`;
}

export function filterByValue<TKey extends string | number | symbol, TValue>(
  obj: Record<TKey, TValue>,
  filter: (value: TValue) => boolean,
) {
  const entries = Object.entries(obj) as [TKey, TValue][];
  return entries
    .filter(([_, value]) => filter(value))
    .reduce((acc, [key, value]) => {
      acc[key as TKey] = value;
      return acc;
    }, {} as Record<TKey, TValue>);
}

export function formatUsers(users: User[]) {
  if (users) {
    users.forEach((u) => {
      if (u.companyUserAccessType === CompanyUserAccessTypes.External) {
        u.name = u.name + ' (External User)';
      }
      if (!u.isActive) {
        u.name = u.name + ' (Inactive User)';
      }
    });
  }

  return users;
}

export function formatUsersWithAvatars({
  users,
  CompanyUserIdClaim,
  classes,
}: {
  users: User[];
  CompanyUserIdClaim: string | number;
  classes: any;
}) {
  const me = users.find(
    (u) => u.id.toString() === CompanyUserIdClaim?.toString(),
  );

  const userList =
    users.length > 0
      ? [
          ...(me ? [{ ...me, name: `Me (${me?.name})` }] : []),
          ...users.filter(
            (u) => u.id.toString() !== CompanyUserIdClaim?.toString(),
          ),
        ].map((a, index) => {
          const className =
            index === 0 ? classes?.avatarMe : classes?.avatarAssignee;
          const isExternal =
            a.companyUserAccessType === CompanyUserAccessTypes.External;
          return {
            ...a,
            avatar: {
              display: `${a.firstName?.charAt(0)?.toUpperCase() || ''}${
                a.lastName?.charAt(0)?.toUpperCase() || ''
              }`,
              className,
              variant: isExternal ? 'rounded' : 'circular',
            },
          };
        })
      : [];

  return userList;
}
