import { createSlice } from '@reduxjs/toolkit';
import {
  CaseIdType,
  isCaseIdValid,
  NoCaseId,
  QuickActionType,
  RefreshKeyType,
  safeIncrement,
} from '../../lib';

const initialState = {
  loading: false,
  listLoading: false,
  isSidebarOpen: false,
  companySettings: {},
  listViews: null,
  companyListViews: {
    data: [],
    hasNoCompanyListViews: false,
    loading: false,
  },
  caseWorkers: [],
  companyUsers: [],
  counties: [],
  medicaidCaseworkers: [],
  insuranceCaseworkers: [],
  judges: [],
  outsourceContacts: [],
  insuranceCompanies: [],
  globalViewVersion: 1,
  viewVersions: {} as Record<RefreshKeyType, Record<CaseIdType, number>>,
  todosCount: 0,
  currentListFilter: '',
  isOverlayOpen: false,
  isListPreviewOpen: false,
  isBoardPreviewOpen: false,
  previewId: null,
  pageNameHeader: '',
  tabbedHeader: false,
  quickAction: {
    caseId: null,
    residentId: null,
    caseName: '',
    quickActionType: QuickActionType.None,
  },
  selectedRowId: null,
  showAddToEMR: false,

  isAnnouncementLogOpen: false,
  currentAnnouncement: {},
  refreshActivityFeed: false,
  isNewDashboardOn: true,
  isNewCaseDetailsOn: undefined, // set default using usePersistedGlobalStateEffect
  notification: undefined,
  bankAccounts: undefined,

  applicationTagOptions: [],
  caseDetailsSourcePage: null,
  dashboardQueryParams: null,
};

export const ui = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    hideNotification(state, action) {
      state.notification = undefined;
    },
    showNotification(state, action) {
      const { message, variant, duration: _duration } = action.payload;
      let duration = _duration;
      if (duration === undefined && variant !== 'error') {
        duration = 6000;
      }
      // @ts-ignore
      state.notification = { message, variant, duration };
    },
    showUpdated(state, action) {
      const { message = 'Your changes have been submitted.' } = action.payload;
      // @ts-ignore
      state.notification = { message };
    },
    showError(state, action) {
      const { message = 'There was an error processing your request.' } =
        action.payload;
      // @ts-ignore
      state.notification = { message, variant: 'error' };
    },
    setUILoading(state, action) {
      state.loading = action.payload;
    },
    setListLoading(state, action) {
      state.listLoading = action.payload;
    },
    toggleSidebar(state, action) {
      state.isSidebarOpen = action.payload;
    },
    setCompanySettings(state, action) {
      state.companySettings = action.payload;
    },
    setListViews(state, action) {
      state.listViews = action.payload;
    },
    setCompanyListViews(state, action) {
      state.companyListViews = action.payload;
    },
    setCompanyListViewsLoading(state, action) {
      state.companyListViews.loading = action.payload;
    },
    setBankAccounts(state, action) {
      state.bankAccounts = action.payload;
    },
    setCaseWorkers(state, action) {
      state.caseWorkers = action.payload;
    },
    setCompanyUsers(state, action) {
      state.companyUsers = action.payload;
    },
    setCounties(state, action) {
      state.counties = action.payload;
    },
    setMedicaidCaseworkers(state, action) {
      state.medicaidCaseworkers = action.payload;
    },
    setInsuranceCaseworkers(state, action) {
      state.insuranceCaseworkers = action.payload;
    },
    setJudges(state, action) {
      state.judges = action.payload;
    },
    setOutsourceContacts(state, action) {
      state.outsourceContacts = action.payload;
    },
    setInsuranceCompanies(state, action) {
      state.insuranceCompanies = action.payload;
    },
    incrementGlobalViewVersion(state) {
      state.globalViewVersion = state.globalViewVersion + 1;
    },
    incrementViewVersion(state, action) {
      const {
        viewName,
        caseId,
      }: { viewName: RefreshKeyType; caseId: CaseIdType } = action.payload;

      if (!state.viewVersions[viewName]) {
        state.viewVersions[viewName] = {} as Record<CaseIdType, number>;
      }
      // always increment the general version because when a specific caseId is updated
      // then of course the general data set has updates, too
      safeIncrement(state.viewVersions[viewName], NoCaseId);

      if (isCaseIdValid(caseId)) {
        safeIncrement(state.viewVersions[viewName], caseId);
      }
    },
    setTodosCount(state, action) {
      state.todosCount = action.payload;
    },
    setCurrentListFilter(state, action) {
      state.currentListFilter = action.payload;
    },
    setOverlayOpen(state, action) {
      state.isOverlayOpen = action.payload;
    },
    setListPreviewOpen(state, action) {
      state.isListPreviewOpen = action.payload;
    },
    setBoardPreviewOpen(state, action) {
      state.isBoardPreviewOpen = action.payload;
    },
    setPreviewOpen(state, action) {
      state.isListPreviewOpen = action.payload;
      state.isBoardPreviewOpen = action.payload;
    },
    setPreviewId(state, action) {
      state.previewId = action.payload;
    },
    setSelectedRowId(state, action) {
      state.selectedRowId = action.payload;
    },
    setQuickActionType(state, action) {
      state.quickAction.quickActionType = action.payload;
    },
    setQuickActionCaseId(state, action) {
      state.quickAction.caseId = action.payload;
    },
    setQuickActionResidentId(state, action) {
      state.quickAction.residentId = action.payload;
    },
    setQuickActionCaseName(state, action) {
      state.quickAction.caseName = action.payload;
    },
    setPageNameHeader(state, action) {
      state.pageNameHeader = action.payload;
    },
    setTabbedHeader(state, action) {
      state.tabbedHeader = action.payload;
    },
    setShowAddToEMR(state, action) {
      state.showAddToEMR = action.payload;
    },
    setAnnouncementLogOpen(state, action) {
      state.isAnnouncementLogOpen = action.payload;
    },
    setCurrentAnnouncement(state, action) {
      state.currentAnnouncement = action.payload;
    },
    setRefreshActivityFeed(state, action) {
      state.refreshActivityFeed = action.payload;
    },
    setIsNewDashboardOn(state, action) {
      state.isNewDashboardOn = action.payload;
    },
    setIsNewCaseDetailsOn(state, action) {
      state.isNewCaseDetailsOn = action.payload;
    },
    setApplicationTagOptions(state, action) {
      state.applicationTagOptions = action.payload;
    },
    setCaseDetailsSourcePage(state, action) {
      state.caseDetailsSourcePage = action.payload;
    },
    setDashboardQueryParams(state, action) {
      state.dashboardQueryParams = action.payload;
    },
  },
});
