import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { TextField, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { CancelIcon, CheckCircleIcon } from '../../../themes';
import { ColoredIcon } from '../ColoredIcon';
import { clsx } from 'clsx';

export function InlineTextEdit({
  editing,
  setEditing,
  value,
  onSave,
  renderPreview,
  inputProps,
  dateInput = false,
  autoFocus = true,
}) {
  const classes = makeStyles((theme) => ({
    inputStyles: {
      margin: 0,
      fontSize: 14,
      '& div input': {
        padding: '6px 0 7px',
        fontSize: 14,
      },
      '& div div button.MuiIconButton-root': {
        paddingLeft: 0,
        marginRight: -4,
      },
      '& div div button.MuiIconButton-root span .MuiSvgIcon-root': {
        fontSize: 20,
      },
    },
    dateStyles: {
      width: 132,
    },
    iconButtons: {
      padding: 2,
    },
    icon: {
      fontSize: 20,
    },
  }))();

  const [editValue, setEditValue] = useState(value);
  const [enterClicked, setEnterClicked] = useState(false);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        setEnterClicked(true);
      }
    },
    [setEnterClicked],
  );

  useEffect(() => {
    if (enterClicked) {
      setEditing(false);
      onSave(editValue);
      setEnterClicked(false);
    }
  }, [enterClicked, editValue, onSave, setEditing]);

  useEffect(() => {
    //reset the editValue after saving
    setEditValue(value);
  }, [editing, value]);

  return editing ? (
    <Fragment>
      {dateInput ? (
        // we are using the mobile picker here to force them to use the picker instead of typing since there is no validation
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDatePicker
            closeOnSelect
            autofocus={autoFocus}
            value={new Date(editValue)}
            onChange={setEditValue}
            className={clsx(classes.inputStyles, classes.dateStyles)}
            slotProps={{
              textField: { variant: 'standard' },
              actionBar: {
                actions: [], //gets rid of the 'ok' button since we close on select
              },
            }}
          />
        </LocalizationProvider>
      ) : (
        <TextField
          fullWidth
          autoFocus={autoFocus}
          variant={'standard'}
          value={editValue ?? ''}
          onChange={(e) => setEditValue(e.target.value)}
          inputProps={inputProps}
          InputProps={{ classes: { input: classes.inputStyles } }}
          onKeyDown={handleKeyDown}
        />
      )}

      <IconButton
        className={classes.iconButtons}
        onClick={() => setEnterClicked(true)}
        size='large'
      >
        <ColoredIcon
          Icon={CheckCircleIcon}
          className={classes.icon}
          iconColor='#0FC076'
        />
      </IconButton>
      <IconButton
        onClick={() => setEditing(false)}
        className={classes.iconButtons}
        size='large'
      >
        <CancelIcon className={classes.icon} />
      </IconButton>
    </Fragment>
  ) : renderPreview && typeof renderPreview === 'function' ? (
    renderPreview()
  ) : null;
}
