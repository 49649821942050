import React, { useCallback, useState, useEffect } from 'react';
import { object, string, bool, array } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { FormModal } from './FormModal';
import { CheckboxInput, SelectInput } from '../common/inputs';
import { useGlobalRefresh, useNotify } from '../../hooks';
import { resourceActions, systemPersistSelectors } from '../../state';
import { validateOnSubmit } from '../../lib';
import { FormWithPadding } from '../../components';

export function AlertSettingsForm({ open, handleClose, id, apiURL }) {
  const dispatch = useDispatch();
  const refresh = useGlobalRefresh();
  const notify = useNotify();
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [state, setState] = useState({
    alertUserTypes: [],
    isActive: false,
    canDismiss: false,
    canSnooze: false,
  });

  const companyUserTypes = useSelector(systemPersistSelectors.companyUserTypes);

  useEffect(() => {
    (async function () {
      if (id) {
        const response = await dispatch(
          resourceActions.fetchResourceById({ baseUrl: apiURL, id }),
        );
        const { data, error } = response;
        if (error) {
          return notify(error?.message || 'An error occurred', 'error');
        }
        const {
          isActive,
          canDismiss,
          canSnooze,
          alertUserTypes: _alertUserTypes,
        } = data;
        const alertUserTypes = _alertUserTypes.map((t) => t.userType);
        setState((s) => ({ isActive, canDismiss, canSnooze, alertUserTypes }));
      }
    })();
  }, [apiURL, dispatch, id, notify]);

  const onSave = useCallback(() => {
    return validateOnSubmit({ values: state, schema, setErrors })
      .then(async () => {
        setSubmitting(true);
        const { alertUserTypes, ...rest } = state;
        const alertUserTypesObj = alertUserTypes.map((t) => ({
          userType: t,
        }));
        const response = await dispatch(
          resourceActions.postResource({
            baseUrl: apiURL,
            payload: {
              companyAlertSetting: {
                ...rest,
                id,
                alertUserTypes: alertUserTypesObj,
              },
            },
          }),
        );
        setSubmitting(false);
        const { data } = response;
        if (data) {
          refresh();
          handleClose();
        }
      })
      .catch((e) => console.error('error ', e));
  }, [apiURL, dispatch, handleClose, id, refresh, state]);

  const onChange = useCallback((e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setState((curState) => ({ ...curState, [name]: value }));
  }, []);

  return (
    <FormModal
      handleClose={handleClose}
      open={open}
      title='Edit Alert'
      onSave={onSave}
      submitting={submitting}
    >
      <FormWithPadding>
        <CheckboxInput
          checkboxes={[
            {
              labelProps: {
                label: 'Active',
              },
              checkboxProps: {
                name: 'isActive',
                checked: state.isActive,
                onChange,
                error: errors.isActive,
              },
            },
            {
              labelProps: {
                label: 'Can dismiss',
              },
              checkboxProps: {
                name: 'canDismiss',
                checked: state.canDismiss,
                onChange,
                error: errors.canDismiss,
              },
            },
            {
              labelProps: {
                label: 'Can snooze',
              },
              checkboxProps: {
                name: 'canSnooze',
                checked: state.canSnooze,
                onChange,
                error: errors.canSnooze,
              },
            },
          ]}
          schema={schema}
        />
        <SelectInput
          fullWidth
          value={state.alertUserTypes}
          name='alertUserTypes'
          menuItems={companyUserTypes}
          onChange={onChange}
          label='User types'
          schema={schema}
          error={errors.alertUserTypes}
          SelectProps={{
            multiple: true,
          }}
          sx={{ mt: 1 }}
        />
      </FormWithPadding>
    </FormModal>
  );
}

const schema = object().shape({
  alertUserTypes: array(string())
    .typeError('This field must be an array')
    .nullable(),
  isActive: bool().nullable().typeError('Must be a Boolean value'),
  canDismiss: bool().nullable().typeError('Must be a Boolean value'),
  canSnooze: bool().nullable().typeError('Must be a Boolean value'),
});
